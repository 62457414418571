import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { PricingPage } from './components/Pricing/PricingPage';
import { LandingPage } from './pages/LandingPage';
import { LibraryPage } from './pages/LibraryPage';
import { ProductPage } from './pages/ProductPage';

function App() {
  const location = useLocation();

  return (
    <div className="min-h-screen bg-zinc-900 flex flex-col">
      <Header />
      
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/library" element={<LibraryPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/product" element={<ProductPage />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;