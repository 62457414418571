import { BehaviorSubject } from 'rxjs';

interface BluetoothState {
  isConnected: boolean;
  device: BluetoothDevice | null;
  characteristic: BluetoothRemoteGATTCharacteristic | null;
}

class BluetoothService {
  private static instance: BluetoothService;
  private state$ = new BehaviorSubject<BluetoothState>({
    isConnected: false,
    device: null,
    characteristic: null,
  });

  // Generic service for ESP32 BLE
  private ESP32_SERVICE = '0000180a-0000-1000-8000-00805f9b34fb';
  private ESP32_CHARACTERISTIC = '00002a57-0000-1000-8000-00805f9b34fb';

  private constructor() {}

  static getInstance(): BluetoothService {
    if (!BluetoothService.instance) {
      BluetoothService.instance = new BluetoothService();
    }
    return BluetoothService.instance;
  }

  getState() {
    return this.state$.getValue();
  }

  onStateChange(callback: (state: BluetoothState) => void) {
    return this.state$.subscribe(callback);
  }

  isWebBluetoothSupported(): boolean {
    return navigator.bluetooth !== undefined;
  }

  async connect(): Promise<void> {
    if (!this.isWebBluetoothSupported()) {
      throw new Error('Web Bluetooth is not supported in this browser. Please use Chrome, Edge, or Opera.');
    }

    try {
      const device = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        optionalServices: [this.ESP32_SERVICE]
      });

      console.log('Connecting to device:', device.name);

      device.addEventListener('gattserverdisconnected', () => {
        console.log('Device disconnected');
        this.state$.next({
          isConnected: false,
          device: null,
          characteristic: null,
        });
      });

      console.log('Connecting to GATT server...');
      const server = await device.gatt?.connect();
      if (!server) throw new Error('Failed to connect to GATT server');

      console.log('Getting primary service...');
      const service = await server.getPrimaryService(this.ESP32_SERVICE);
      
      console.log('Getting characteristic...');
      const characteristic = await service.getCharacteristic(this.ESP32_CHARACTERISTIC);

      console.log('Connected successfully');
      this.state$.next({
        isConnected: true,
        device,
        characteristic,
      });
    } catch (error) {
      console.error('Bluetooth connection error:', error);
      throw error;
    }
  }

  async disconnect(): Promise<void> {
    const { device } = this.state$.getValue();
    if (device?.gatt?.connected) {
      device.gatt.disconnect();
    }
    this.state$.next({
      isConnected: false,
      device: null,
      characteristic: null,
    });
  }

  async sendLEDCommand(noteId: string, channel: number, isOn: boolean): Promise<void> {
    const { characteristic } = this.state$.getValue();
    if (!characteristic) return;

    try {
      // Format: NOTE:CHANNEL:STATE (e.g., "C4:1:1")
      const command = `${noteId}:${channel}:${isOn ? '1' : '0'}`;
      await characteristic.writeValue(new TextEncoder().encode(command));
    } catch (error) {
      console.error('Error sending LED command:', error);
    }
  }
}

export const bluetoothService = BluetoothService.getInstance();