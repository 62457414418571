import { Midi } from '@tonejs/midi';
import { audioService } from './audio';
import type { PianoNote } from './piano';

interface ActiveNote {
  noteId: string;
  channel: number;
}

export class MidiPlayer {
  private midi: Midi | null = null;
  private isPlaying = false;
  private currentTime = 0;
  private startTime = 0;
  private pausedTime = 0;
  private activeNotes: Map<string, ActiveNote> = new Map();
  private onNotesChange: (notes: Map<string, ActiveNote>) => void;
  private scheduledNotes: Map<string, number> = new Map();
  private playbackSpeed = 1;
  private onTimeUpdate?: (time: number) => void;
  private animationFrameId: number | null = null;
  private scheduledTimeouts: Set<number> = new Set();

  constructor(onNotesChange: (notes: Map<string, ActiveNote>) => void) {
    this.onNotesChange = onNotesChange;
  }

  setSpeed(speed: number) {
    const wasPlaying = this.isPlaying;
    if (wasPlaying) {
      this.pause();
    }
    this.playbackSpeed = speed;
    if (wasPlaying) {
      this.start();
    }
  }

  setOnTimeUpdate(callback: (time: number) => void) {
    this.onTimeUpdate = callback;
  }

  getDuration(): number {
    return this.midi?.duration || 0;
  }

  getCurrentTime(): number {
    return this.currentTime;
  }

  seekTo(time: number) {
    const wasPlaying = this.isPlaying;
    if (wasPlaying) {
      this.pause();
    }
    
    this.currentTime = time;
    this.pausedTime = time;
    this.startTime = performance.now() - (time * 1000 / this.playbackSpeed);
    
    if (wasPlaying) {
      this.start();
    } else {
      this.onTimeUpdate?.(this.currentTime);
    }
  }

  async loadMidiFile(file: File) {
    const arrayBuffer = await file.arrayBuffer();
    this.midi = new Midi(arrayBuffer);
    this.currentTime = 0;
    this.pausedTime = 0;
    this.activeNotes.clear();
    this.scheduledNotes.clear();
    this.onNotesChange(this.activeNotes);
    this.onTimeUpdate?.(0);

    await audioService.initialize();
  }

  private midiNoteToNote(midiNote: number): PianoNote {
    const noteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
    const octave = Math.floor(midiNote / 12) - 1;
    const noteName = noteNames[midiNote % 12];
    return {
      note: noteName as any,
      octave: Math.max(0, Math.min(8, octave)) as any,
      isBlack: noteName.includes('#')
    };
  }

  private scheduleNote(note: any, channel: number, delay: number) {
    const timeoutId = window.setTimeout(() => {
      const pianoNote = this.midiNoteToNote(note.midi);
      const noteId = `${pianoNote.note}${pianoNote.octave}`;
      
      // Start note
      this.activeNotes.set(noteId, { noteId, channel });
      audioService.playNote(pianoNote, true);
      this.onNotesChange(new Map(this.activeNotes));

      // Schedule note end
      const endTimeoutId = window.setTimeout(() => {
        this.activeNotes.delete(noteId);
        audioService.stopNote(pianoNote);
        this.onNotesChange(new Map(this.activeNotes));
        this.scheduledTimeouts.delete(endTimeoutId);
      }, note.duration * 1000 / this.playbackSpeed);

      this.scheduledTimeouts.add(endTimeoutId);
      this.scheduledTimeouts.delete(timeoutId);
    }, delay);

    this.scheduledTimeouts.add(timeoutId);
  }

  async start() {
    if (!this.midi || this.isPlaying) return;
    
    await audioService.resume();
    
    this.isPlaying = true;
    this.startTime = performance.now() - (this.pausedTime * 1000 / this.playbackSpeed);

    // Schedule all notes ahead of time
    this.midi.tracks.forEach(track => {
      const channel = track.channel + 1;
      track.notes.forEach(note => {
        const delay = (note.time - this.currentTime) * 1000 / this.playbackSpeed;
        if (delay >= 0) {
          this.scheduleNote(note, channel, delay);
        }
      });
    });

    // Start the time update loop
    const updateTime = () => {
      if (!this.isPlaying) return;
      
      const now = performance.now();
      this.currentTime = (now - this.startTime) * this.playbackSpeed / 1000;
      this.onTimeUpdate?.(this.currentTime);

      if (this.currentTime < this.midi!.duration) {
        this.animationFrameId = requestAnimationFrame(updateTime);
      } else {
        this.stop();
      }
    };

    this.animationFrameId = requestAnimationFrame(updateTime);
  }

  pause() {
    if (!this.isPlaying) return;
    
    this.isPlaying = false;
    this.pausedTime = this.currentTime;
    
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }

    // Clear all scheduled timeouts
    this.scheduledTimeouts.forEach(id => clearTimeout(id));
    this.scheduledTimeouts.clear();
    
    // Stop all active notes
    this.activeNotes.forEach(({ noteId }) => {
      const [note, octave] = noteId.split('');
      audioService.stopNote({ note: note as any, octave: parseInt(octave) as any, isBlack: note.includes('#') });
    });
    this.activeNotes.clear();
    this.onNotesChange(this.activeNotes);
  }

  stop() {
    this.isPlaying = false;
    this.currentTime = 0;
    this.pausedTime = 0;
    
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }

    // Clear all scheduled timeouts
    this.scheduledTimeouts.forEach(id => clearTimeout(id));
    this.scheduledTimeouts.clear();
    
    this.activeNotes.forEach(({ noteId }) => {
      const [note, octave] = noteId.split('');
      audioService.stopNote({ note: note as any, octave: parseInt(octave) as any, isBlack: note.includes('#') });
    });
    this.activeNotes.clear();
    this.onNotesChange(this.activeNotes);
    this.onTimeUpdate?.(0);
  }
}