import React, { useState } from 'react';
import { LogIn, Music, User } from 'lucide-react';
import { AuthModal } from '../Auth/AuthModal';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export function Header() {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState<'signin' | 'signup'>('signin');
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSignIn = () => {
    setAuthMode('signin');
    setShowAuthModal(true);
  };

  const handleSignUp = () => {
    setAuthMode('signup');
    setShowAuthModal(true);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleProfileClick = () => {
    navigate('/library');
  };

  return (
    <>
      <header className="sticky top-0 w-full bg-zinc-900/95 backdrop-blur-sm border-b border-zinc-800 z-50">
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
          {/* Logo */}
          <button 
            onClick={handleLogoClick}
            className="flex-shrink-0 flex items-center gap-2 hover:opacity-80 transition-opacity"
          >
            <div className="relative">
              <Music className="w-6 h-6 text-blue-500" />
              <div className="absolute -top-1 -right-1 w-4 h-4 bg-gradient-to-tr from-blue-500 to-transparent transform rotate-45 opacity-75" />
            </div>
            <span className="text-xl font-bold text-white hidden sm:block">PIANOLIGHTS</span>
          </button>

          {/* Navigation */}
          <div className="flex items-center gap-2 sm:gap-4 md:gap-6">
            <a 
              href="#pricing" 
              className="text-sm text-zinc-300 hover:text-white transition-colors px-2 py-1"
            >
              Pricing
            </a>
            
            {user ? (
              <button
                onClick={handleProfileClick}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-600 hover:bg-blue-700 transition-colors"
                title="Go to Library"
              >
                <User className="w-4 h-4 text-white" />
              </button>
            ) : (
              <>
                <button 
                  onClick={handleSignIn}
                  className="text-sm text-zinc-300 hover:text-white transition-colors px-2 py-1"
                >
                  Log in
                </button>
                <button 
                  onClick={handleSignUp}
                  className="text-sm bg-blue-600 text-white px-3 py-1.5 rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-1.5"
                >
                  <LogIn className="w-4 h-4" />
                  <span>Sign up</span>
                </button>
              </>
            )}
          </div>
        </div>
      </header>

      <AuthModal 
        isOpen={showAuthModal} 
        onClose={() => setShowAuthModal(false)} 
        mode={authMode} 
      />
    </>
  );
}