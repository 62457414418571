import React, { useState, useEffect } from 'react';
import { Bluetooth, X, Loader2, AlertCircle } from 'lucide-react';
import { bluetoothService } from '../../services/bluetooth';

interface BluetoothModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function BluetoothModal({ isOpen, onClose }: BluetoothModalProps) {
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSupported, setIsSupported] = useState(true);

  useEffect(() => {
    setIsSupported(bluetoothService.isWebBluetoothSupported());
  }, []);

  if (!isOpen) return null;

  const handleConnect = async () => {
    setIsConnecting(true);
    setError(null);

    try {
      await bluetoothService.connect();
      onClose();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Failed to connect to device');
      }
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-zinc-900 rounded-lg p-6 max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-zinc-400 hover:text-white"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="text-center">
          <div className="w-16 h-16 bg-blue-600/10 rounded-full flex items-center justify-center mx-auto mb-4">
            <Bluetooth className="w-8 h-8 text-blue-500" />
          </div>
          
          <h2 className="text-2xl font-bold text-white mb-2">
            Connect PianoLED
          </h2>

          {!isSupported ? (
            <div className="bg-red-500/10 text-red-400 p-4 rounded-lg mb-4 flex items-center gap-2">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <p className="text-sm">
                Web Bluetooth is not supported in this browser. Please use Chrome, Edge, or Opera.
              </p>
            </div>
          ) : (
            <>
              <p className="text-zinc-400 mb-6">
                Make sure your PianoLED device is turned on and within range
              </p>

              {error && (
                <div className="bg-red-500/10 text-red-400 p-3 rounded-lg mb-4 flex items-center gap-2">
                  <AlertCircle className="w-5 h-5 flex-shrink-0" />
                  <p className="text-sm">{error}</p>
                </div>
              )}

              <button
                onClick={handleConnect}
                disabled={isConnecting}
                className="w-full flex items-center justify-center gap-2 bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition-colors disabled:bg-blue-600/50 disabled:cursor-not-allowed"
              >
                {isConnecting ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Connecting...
                  </>
                ) : (
                  <>
                    <Bluetooth className="w-5 h-5" />
                    Connect Device
                  </>
                )}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}