import React, { useState } from 'react';
import { ArrowRight, Bluetooth, Battery, Zap, Sparkles, Wifi, Check, X } from 'lucide-react';

export function ProductPage() {
  const [selectedPlan, setSelectedPlan] = useState<'free' | 'pro' | null>(null);

  const handleBuyNow = () => {
    // TODO: Implement checkout logic
    console.log('Proceeding to checkout with plan:', selectedPlan);
  };

  const planFeatures = {
    free: [
      { feature: 'Access to 100 curated piano pieces', included: true },
      { feature: 'Basic guided practice mode (one hand at a time)', included: true },
      { feature: 'Adjustable playback speed', included: true },
      { feature: 'Default LED color (white)', included: true },
      { feature: 'Progress tracking for free pieces', included: true },
      { feature: 'Custom LED colors', included: false },
      { feature: 'Advanced practice mode with dual-hand sync', included: false },
      { feature: 'Upload custom MIDI files', included: false },
      { feature: 'Access to 5,000+ pieces library', included: false },
      { feature: 'Loop sections for practice', included: false },
    ],
    pro: [
      { feature: 'Access to 100 curated piano pieces', included: true },
      { feature: 'Basic guided practice mode (one hand at a time)', included: true },
      { feature: 'Adjustable playback speed', included: true },
      { feature: 'Default LED color (white)', included: true },
      { feature: 'Progress tracking for free pieces', included: true },
      { feature: 'Custom LED colors', included: true },
      { feature: 'Advanced practice mode with dual-hand sync', included: true },
      { feature: 'Upload custom MIDI files', included: true },
      { feature: 'Access to 5,000+ pieces library', included: true },
      { feature: 'Loop sections for practice', included: true },
    ],
  };

  return (
    <main className="bg-zinc-900">
      {/* Hero Section */}
      <section className="min-h-[80vh] relative flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-500/20 to-transparent pointer-events-none" />
        <div className="max-w-7xl mx-auto px-4 py-20 flex flex-col lg:flex-row items-center gap-12">
          {/* Product Image */}
          <div className="flex-1 relative">
            <div className="aspect-[16/9] bg-zinc-800 rounded-lg overflow-hidden shadow-2xl">
              <img 
                src="https://images.unsplash.com/photo-1552056776-9b5657118ca4?auto=format&fit=crop&q=80"
                alt="PIANOLIGHTS LED Strip"
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Product Info */}
          <div className="flex-1 space-y-6">
            <div className="space-y-2">
              <h1 className="text-4xl md:text-5xl font-bold text-white">
                PIANOLIGHTS LED Strip
              </h1>
              <p className="text-xl text-zinc-300">
                Transform your piano practice with real-time LED guidance
              </p>
            </div>

            <div className="flex items-baseline gap-4">
              <span className="text-4xl font-bold text-white">€59.99</span>
              <span className="text-zinc-400 line-through">€79.99</span>
              <span className="bg-blue-500/20 text-blue-300 px-2 py-1 rounded text-sm">
                Save €20
              </span>
            </div>

            {/* Plan Selection */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-white">Choose your plan:</h3>
              <div className="grid gap-4">
                <button
                  onClick={() => setSelectedPlan('free')}
                  className={`p-6 rounded-lg border-2 text-left transition-all ${
                    selectedPlan === 'free'
                      ? 'border-blue-500 bg-blue-500/10 scale-[1.02]'
                      : 'border-zinc-700 hover:border-zinc-600'
                  }`}
                >
                  <div className="flex justify-between items-center mb-2">
                    <div>
                      <span className="font-medium text-white text-lg">Free Plan</span>
                      <p className="text-sm text-zinc-400 mt-1">
                        Perfect for getting started
                      </p>
                    </div>
                    <span className="text-zinc-400">€0/month</span>
                  </div>
                  <ul className="mt-4 space-y-2">
                    {planFeatures.free.slice(0, 4).map(({ feature, included }) => (
                      <li key={feature} className="flex items-center gap-2 text-sm">
                        {included ? (
                          <Check className="w-4 h-4 text-blue-500" />
                        ) : (
                          <X className="w-4 h-4 text-zinc-600" />
                        )}
                        <span className={included ? 'text-zinc-300' : 'text-zinc-500'}>
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </button>

                <button
                  onClick={() => setSelectedPlan('pro')}
                  className={`p-6 rounded-lg border-2 text-left relative overflow-hidden transition-all ${
                    selectedPlan === 'pro'
                      ? 'border-blue-500 bg-blue-500/10 scale-[1.02]'
                      : 'border-zinc-700 hover:border-zinc-600'
                  }`}
                >
                  <div className="absolute top-3 right-3">
                    <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
                      RECOMMENDED
                    </span>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <div>
                      <span className="font-medium text-white text-lg">Pro Plan</span>
                      <p className="text-sm text-zinc-400 mt-1">
                        For serious piano enthusiasts
                      </p>
                    </div>
                    <span className="text-zinc-400">€4.99/month</span>
                  </div>
                  <ul className="mt-4 space-y-2">
                    {planFeatures.pro.slice(0, 4).map(({ feature, included }) => (
                      <li key={feature} className="flex items-center gap-2 text-sm">
                        {included ? (
                          <Check className="w-4 h-4 text-blue-500" />
                        ) : (
                          <X className="w-4 h-4 text-zinc-600" />
                        )}
                        <span className={included ? 'text-zinc-300' : 'text-zinc-500'}>
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </button>
              </div>
            </div>

            <button
              onClick={handleBuyNow}
              disabled={!selectedPlan}
              className={`w-full py-4 rounded-lg flex items-center justify-center gap-2 text-lg font-medium transition-all ${
                selectedPlan
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-zinc-700 text-zinc-400 cursor-not-allowed'
              }`}
            >
              Buy Now
              <ArrowRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </section>

      {/* Full Plan Comparison */}
      <section className="py-20 bg-zinc-800/50">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Plan Comparison
          </h2>
          <div className="bg-zinc-800 rounded-xl overflow-hidden">
            <div className="grid grid-cols-3 gap-4 p-6 border-b border-zinc-700">
              <div className="text-zinc-400">Features</div>
              <div className="text-center">
                <div className="text-white font-medium">Free Plan</div>
                <div className="text-zinc-400 text-sm">€0/month</div>
              </div>
              <div className="text-center">
                <div className="text-white font-medium">Pro Plan</div>
                <div className="text-zinc-400 text-sm">€4.99/month</div>
              </div>
            </div>
            <div className="divide-y divide-zinc-700">
              {planFeatures.pro.map(({ feature }, index) => (
                <div key={feature} className="grid grid-cols-3 gap-4 p-4">
                  <div className="text-zinc-300 text-sm">{feature}</div>
                  <div className="flex justify-center">
                    {planFeatures.free[index].included ? (
                      <Check className="w-5 h-5 text-blue-500" />
                    ) : (
                      <X className="w-5 h-5 text-zinc-600" />
                    )}
                  </div>
                  <div className="flex justify-center">
                    <Check className="w-5 h-5 text-blue-500" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Technical Specifications */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Technical Specifications
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <SpecCard
              icon={<Bluetooth className="w-6 h-6 text-blue-400" />}
              title="Bluetooth 5.0"
              description="Low latency connection with up to 10m range for seamless performance"
              features={[
                'Class 1 Bluetooth with extended range',
                'Ultra-low latency (<10ms)',
                'Auto-reconnect capability',
              ]}
            />
            <SpecCard
              icon={<Battery className="w-6 h-6 text-blue-400" />}
              title="Long Battery Life"
              description="20+ hours of continuous use with fast charging support"
              features={[
                'USB-C fast charging (2 hours)',
                'Smart power management',
                'Battery level indicator',
              ]}
            />
            <SpecCard
              icon={<Zap className="w-6 h-6 text-blue-400" />}
              title="Ultra-Bright LEDs"
              description="88 individually addressable RGB LEDs for precise guidance"
              features={[
                '16 million colors',
                'Adjustable brightness',
                'Eye-comfort diffusion',
              ]}
            />
          </div>
        </div>
      </section>

      {/* What's in the Box */}
      <section className="py-20 bg-zinc-800/50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            What's in the Box
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <div className="bg-zinc-800 rounded-xl p-8">
              <h3 className="text-xl font-medium text-white mb-6">Hardware</h3>
              <ul className="space-y-4">
                <li className="flex items-center gap-3 text-zinc-300">
                  <div className="w-2 h-2 bg-blue-500 rounded-full" />
                  <div>
                    <div className="font-medium">PIANOLIGHTS LED Strip</div>
                    <p className="text-sm text-zinc-400">88-key compatible LED strip with diffuser</p>
                  </div>
                </li>
                <li className="flex items-center gap-3 text-zinc-300">
                  <div className="w-2 h-2 bg-blue-500 rounded-full" />
                  <div>
                    <div className="font-medium">Premium USB-C Cable</div>
                    <p className="text-sm text-zinc-400">1.5m braided cable for charging</p>
                  </div>
                </li>
                <li className="flex items-center gap-3 text-zinc-300">
                  <div className="w-2 h-2 bg-blue-500 rounded-full" />
                  <div>
                    <div className="font-medium">Professional Mounting Kit</div>
                    <p className="text-sm text-zinc-400">Non-damaging adhesive strips and clips</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="bg-zinc-800 rounded-xl p-8">
              <h3 className="text-xl font-medium text-white mb-6">Documentation & Extras</h3>
              <ul className="space-y-4">
                <li className="flex items-center gap-3 text-zinc-300">
                  <div className="w-2 h-2 bg-blue-500 rounded-full" />
                  <div>
                    <div className="font-medium">Quick Start Guide</div>
                    <p className="text-sm text-zinc-400">Step-by-step setup instructions</p>
                  </div>
                </li>
                <li className="flex items-center gap-3 text-zinc-300">
                  <div className="w-2 h-2 bg-blue-500 rounded-full" />
                  <div>
                    <div className="font-medium">2-Year Warranty Card</div>
                    <p className="text-sm text-zinc-400">Extended coverage for peace of mind</p>
                  </div>
                </li>
                <li className="flex items-center gap-3 text-zinc-300">
                  <div className="w-2 h-2 bg-blue-500 rounded-full" />
                  <div>
                    <div className="font-medium">Premium Carrying Case</div>
                    <p className="text-sm text-zinc-400">Protective case for storage and travel</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

function SpecCard({ 
  icon, 
  title, 
  description,
  features 
}: { 
  icon: React.ReactNode; 
  title: string; 
  description: string;
  features: string[];
}) {
  return (
    <div className="bg-zinc-800 rounded-xl p-8">
      <div className="flex items-center gap-3 mb-4">
        {icon}
        <h3 className="text-lg font-medium text-white">{title}</h3>
      </div>
      <p className="text-zinc-400 mb-6">{description}</p>
      <ul className="space-y-2">
        {features.map((feature) => (
          <li key={feature} className="flex items-center gap-2 text-sm text-zinc-300">
            <Check className="w-4 h-4 text-blue-500 flex-shrink-0" />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
}