import React from 'react';
import { Check } from 'lucide-react';

export function PricingPage() {
  return (
    <div className="min-h-screen bg-zinc-900 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white mb-4">Simple, Transparent Pricing</h2>
          <p className="text-zinc-400 max-w-2xl mx-auto">
            Choose the plan that best fits your learning journey. Upgrade or downgrade at any time.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {/* Free Plan */}
          <div className="bg-zinc-800 rounded-2xl p-8 border border-zinc-700">
            <div className="mb-6">
              <h3 className="text-xl font-semibold text-white mb-2">Free Plan</h3>
              <p className="text-zinc-400">Perfect for getting started</p>
              <div className="mt-4">
                <span className="text-3xl font-bold text-white">$0</span>
                <span className="text-zinc-400">/month</span>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 text-blue-500 mt-0.5 flex-shrink-0" />
                <span className="text-zinc-300">Access to 100 curated piano pieces from the library of 5,000+ songs</span>
              </div>
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 text-blue-500 mt-0.5 flex-shrink-0" />
                <span className="text-zinc-300">Basic guided practice mode (one hand at a time)</span>
              </div>
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 text-blue-500 mt-0.5 flex-shrink-0" />
                <span className="text-zinc-300">Adjustable playback speed for slowed or faster practice</span>
              </div>
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 text-blue-500 mt-0.5 flex-shrink-0" />
                <span className="text-zinc-300">Default LED light color for guidance (no customization)</span>
              </div>
            </div>

            <button className="w-full mt-8 px-4 py-2 bg-zinc-700 text-white rounded-lg hover:bg-zinc-600 transition-colors">
              Get Started
            </button>
          </div>

          {/* Pro Plan */}
          <div className="bg-blue-600 rounded-2xl p-8 relative overflow-hidden">
            <div className="absolute top-3 right-3">
              <span className="bg-blue-800 text-white text-xs font-semibold px-3 py-1 rounded-full">
                MOST POPULAR
              </span>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold text-white mb-2">Pro Plan</h3>
              <p className="text-blue-200">For serious piano enthusiasts</p>
              <div className="mt-4">
                <span className="text-3xl font-bold text-white">$4.99</span>
                <span className="text-blue-200">/month</span>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 text-white mt-0.5 flex-shrink-0" />
                <span className="text-blue-50">Full access to the entire library of 5,000+ piano pieces</span>
              </div>
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 text-white mt-0.5 flex-shrink-0" />
                <span className="text-blue-50">Advanced guided practice mode with dual-hand synchronization and looping</span>
              </div>
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 text-white mt-0.5 flex-shrink-0" />
                <span className="text-blue-50">Custom LED colors for personal preference or hand-specific practice</span>
              </div>
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 text-white mt-0.5 flex-shrink-0" />
                <span className="text-blue-50">Unlimited progress tracking across all pieces</span>
              </div>
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 text-white mt-0.5 flex-shrink-0" />
                <span className="text-blue-50">Upload and practice with custom MIDI files</span>
              </div>
            </div>

            <button className="w-full mt-8 px-4 py-2 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors">
              Upgrade Now
            </button>
          </div>
        </div>

        <div className="text-center mt-12">
          <p className="text-zinc-400">
            All plans include basic features like adjustable playback speed and guided practice mode.
            <br />
            Need help choosing? <a href="#contact" className="text-blue-400 hover:text-blue-300">Contact us</a>
          </p>
        </div>
      </div>
    </div>
  );
}