import React from 'react';
import { ArrowRight, Music, Sparkles, Zap, Clock, Upload, Settings, Star } from 'lucide-react';

export function LandingPage() {
  return (
    <main className="bg-zinc-900">
      {/* Hero Section */}
      <section className="min-h-[90vh] relative flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-500/20 to-transparent pointer-events-none" />
        <div className="max-w-7xl mx-auto px-4 py-20 text-center relative z-10">
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold text-white mb-6">
            Learn Piano with
            <br />
            <span className="bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">
              LED Guidance
            </span>
          </h1>
          <p className="text-xl md:text-2xl text-zinc-300 mb-12 max-w-3xl mx-auto">
            Transform your piano practice with our innovative LED strip that lights up the keys you need to play. Connect via Bluetooth and start learning today.
          </p>
          <a 
            href="/product" 
            className="inline-flex items-center justify-center gap-2 bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors text-lg font-medium"
          >
            Get Started
            <ArrowRight className="w-5 h-5" />
          </a>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-zinc-800/50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Features that Make Learning Piano Easier
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={<Sparkles className="w-6 h-6 text-blue-400" />}
              title="LED Guidance"
              description="Follow along as LEDs light up each key you need to play, making learning intuitive and engaging."
            />
            <FeatureCard
              icon={<Clock className="w-6 h-6 text-blue-400" />}
              title="Practice at Your Pace"
              description="Adjust playback speed to master difficult passages and gradually build up to full tempo."
            />
            <FeatureCard
              icon={<Upload className="w-6 h-6 text-blue-400" />}
              title="Extensive Library"
              description="Access thousands of pieces from classical to contemporary, with new additions regularly."
            />
            <FeatureCard
              icon={<Settings className="w-6 h-6 text-blue-400" />}
              title="Customizable Practice"
              description="Practice hands separately or together, loop sections, and track your progress."
            />
            <FeatureCard
              icon={<Zap className="w-6 h-6 text-blue-400" />}
              title="Real-time Feedback"
              description="Get instant visual feedback on timing and accuracy to improve your playing."
            />
            <FeatureCard
              icon={<Music className="w-6 h-6 text-blue-400" />}
              title="MIDI Compatible"
              description="Upload your own MIDI files to learn any piece you want."
            />
          </div>
        </div>
      </section>

      {/* Video Demo Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            See It in Action
          </h2>
          <div className="aspect-video bg-zinc-800 rounded-lg overflow-hidden max-w-4xl mx-auto">
            <img
              src="https://images.unsplash.com/photo-1552056776-9b5657118ca4?auto=format&fit=crop&q=80"
              alt="PIANOLIGHTS Demo"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </section>

      {/* Reviews Section */}
      <section className="py-20 bg-zinc-800/50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            What Our Users Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <ReviewCard
              content="PIANOLIGHTS has transformed how I practice piano. The LED guidance makes learning new pieces so much more intuitive."
              author="Sarah M."
              title="Piano Student"
              rating={5}
            />
            <ReviewCard
              content="As a piano teacher, I've seen my students progress much faster using this system. It's a game-changer for piano education."
              author="Michael R."
              title="Piano Teacher"
              rating={5}
            />
            <ReviewCard
              content="The ability to slow down pieces and practice hands separately with visual guidance has helped me tackle pieces I never thought I could play."
              author="David L."
              title="Amateur Pianist"
              rating={5}
            />
          </div>
        </div>
      </section>
    </main>
  );
}

function FeatureCard({ 
  icon, 
  title, 
  description 
}: { 
  icon: React.ReactNode; 
  title: string; 
  description: string;
}) {
  return (
    <div className="bg-zinc-800 rounded-xl p-8">
      <div className="flex items-center gap-3 mb-4">
        {icon}
        <h3 className="text-lg font-medium text-white">{title}</h3>
      </div>
      <p className="text-zinc-400">{description}</p>
    </div>
  );
}

function ReviewCard({ 
  content, 
  author, 
  title, 
  rating 
}: { 
  content: string; 
  author: string; 
  title: string; 
  rating: number;
}) {
  return (
    <div className="bg-zinc-800 rounded-xl p-8">
      <div className="flex gap-1 mb-4">
        {Array.from({ length: rating }).map((_, i) => (
          <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
        ))}
      </div>
      <p className="text-zinc-300 mb-4">{content}</p>
      <div>
        <div className="font-medium text-white">{author}</div>
        <div className="text-sm text-zinc-400">{title}</div>
      </div>
    </div>
  );
}