import Soundfont from 'soundfont-player';
import type { PianoNote } from './piano';

class AudioService {
  private audioContext: AudioContext | null = null;
  private instrument: any = null;
  private activeNotes: Map<string, any> = new Map();
  private isInitialized = false;
  private scheduledNotes: Map<string, number> = new Map();
  private lookAheadTime = 0.1; // 100ms look-ahead
  private scheduleInterval = 16.67; // ~60fps in ms

  async initialize(): Promise<void> {
    if (this.isInitialized) return;
    
    try {
      this.audioContext = new AudioContext({
        latencyHint: 'interactive',
        sampleRate: 44100
      });
      
      // Create a buffer for smoother playback
      const bufferSize = 1024;
      const scriptNode = this.audioContext.createScriptProcessor(bufferSize, 1, 1);
      scriptNode.connect(this.audioContext.destination);
      
      this.instrument = await Soundfont.instrument(this.audioContext, 'acoustic_grand_piano', {
        format: 'mp3',
        soundfont: 'MusyngKite'
      });
      
      this.isInitialized = true;
    } catch (error) {
      console.error('Error initializing audio:', error);
    }
  }

  private formatNote(note: PianoNote): string {
    return `${note.note.toLowerCase()}${note.octave}`;
  }

  async playNote(note: PianoNote, isMidiPlayback = false): Promise<void> {
    if (!isMidiPlayback) return;

    try {
      if (!this.isInitialized) {
        await this.initialize();
      }

      if (!this.instrument) return;

      const noteId = `${note.note}${note.octave}`;
      const formattedNote = this.formatNote(note);
      
      // Schedule note slightly ahead of time
      const currentTime = this.audioContext?.currentTime || 0;
      const startTime = currentTime + 0.005; // 5ms ahead
      
      this.stopNote(note);
      
      const player = await this.instrument.play(formattedNote, startTime, {
        duration: 2,
        gain: 0.8,
        attack: 0.002,
        release: 0.1
      });
      
      this.activeNotes.set(noteId, player);
      this.scheduledNotes.set(noteId, startTime);
    } catch (error) {
      console.error('Error playing note:', error);
    }
  }

  stopNote(note: PianoNote): void {
    const noteId = `${note.note}${note.octave}`;
    const player = this.activeNotes.get(noteId);
    
    if (player) {
      const releaseTime = this.audioContext?.currentTime || 0;
      player.stop(releaseTime + 0.05); // Small delay for natural release
      this.activeNotes.delete(noteId);
      this.scheduledNotes.delete(noteId);
    }
  }

  async resume(): Promise<void> {
    if (this.audioContext?.state === 'suspended') {
      await this.audioContext.resume();
    }
  }

  cleanup(): void {
    this.activeNotes.forEach(player => player.stop());
    this.activeNotes.clear();
    this.scheduledNotes.clear();
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }
    this.instrument = null;
    this.isInitialized = false;
  }
}

export const audioService = new AudioService();