import React, { useState, useEffect } from 'react';
import { Bluetooth } from 'lucide-react';
import { Keyboard } from '../components/Piano/Keyboard';
import { PlayButton } from '../components/MidiPlayer/PlayButton';
import { SpeedControl } from '../components/MidiPlayer/SpeedControl';
import { ProgressBar } from '../components/MidiPlayer/ProgressBar';
import { LibraryBrowser } from '../components/Library/LibraryBrowser';
import { BluetoothModal } from '../components/Bluetooth/BluetoothModal';
import { MidiPlayer } from '../utils/midiPlayer';
import { bluetoothService } from '../services/bluetooth';
import { MidiPiece } from '../types/midi';
import type { PianoNote } from '../utils/piano';

export function LibraryPage() {
  const [activeNotes, setActiveNotes] = useState<Map<string, { noteId: string; channel: number }>>(new Map());
  const [midiPlayer] = useState(() => new MidiPlayer(setActiveNotes));
  const [hasMidiFile, setHasMidiFile] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentPiece, setCurrentPiece] = useState<MidiPiece | null>(null);
  const [showBluetoothModal, setShowBluetoothModal] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const subscription = bluetoothService.onStateChange((state) => {
      setIsConnected(state.isConnected);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    // Send LED commands when notes change
    activeNotes.forEach(({ noteId, channel }) => {
      if (isConnected) {
        bluetoothService.sendLEDCommand(noteId, channel, true).catch(console.error);
      }
    });
  }, [activeNotes, isConnected]);

  const handleKeyPress = (note: PianoNote) => {
    const noteId = `${note.note}${note.octave}`;
    setActiveNotes(prev => new Map(prev).set(noteId, { noteId, channel: 1 }));
  };

  const handleKeyRelease = (note: PianoNote) => {
    const noteId = `${note.note}${note.octave}`;
    if (isConnected) {
      bluetoothService.sendLEDCommand(noteId, 1, false).catch(console.error);
    }
    setActiveNotes(prev => {
      const newMap = new Map(prev);
      newMap.delete(noteId);
      return newMap;
    });
  };

  // ... rest of the component code ...

  return (
    <main className="flex-1 p-4 md:p-6 lg:p-8 space-y-8">
      <div className="flex justify-between items-center max-w-[90vw] mx-auto">
        <button 
          onClick={() => setShowBluetoothModal(true)}
          className={`flex items-center gap-2 ${
            isConnected ? 'bg-green-600' : 'bg-blue-600'
          } text-white px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors group`}
        >
          <Bluetooth className="w-5 h-5 transition-transform group-hover:scale-110" />
          <span>{isConnected ? 'Connected' : 'Connect PianoLED'}</span>
        </button>
      </div>

      {/* ... rest of the JSX ... */}

      <BluetoothModal 
        isOpen={showBluetoothModal} 
        onClose={() => setShowBluetoothModal(false)} 
      />
    </main>
  );
}